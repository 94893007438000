import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from '../services/app.service';
import { TELEMED_CRED, TOKEN } from '../items/storage-names';
import { environment } from 'src/environments/environment';
import { getStorage } from '../helper/storage';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private appService: AppService,
    private toastr: ToastrService,
    private router: Router,
    private cookieService: CookieService) {
    //
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const userToken = localStorage.getItem(TOKEN);

    if (userToken != null) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${userToken}`
        }
      });
    }

    /* if(request?.url?.indexOf(environment.mwell_telemend_url) !== -1) {
      const token = getStorage(TELEMED_CRED, true)?.token;
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          "Ocp-Apim-Subscription-Key" : environment.mwell_telemend_subscription_key
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN)}`
        }
      });
    } */

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          if (err.error.message) {
            this.toastr.error(err.error.message, err?.error?.title, {
              timeOut: err.error?.duration || 5000,
            });
            this.appService.logout();
          }
        }

        if (err.status === 422 || err.status === 403 || err.status === 404 || err.status === 413 || err.status === 429) {

          if (err.status === 422 && err.error?.errors) {
            const validationErrors = err.error.errors;
            let errorMessage = Object.keys(validationErrors)
              .map((key) => validationErrors[key].join(' '))
              .join('\n');

            this.toastr.error(errorMessage.replace(/\n/g, '<br>'), 'Validation Error', {
              timeOut: 5000,
              enableHtml: true,
            });
          }


          else if (err.error.message) {
            switch (err.error.type) {
              case 'info':
                this.toastr.info(err.error.message, err?.error?.title, {
                  timeOut: err.error?.duration || 5000,
                });
                break;
              default:
                this.toastr.error(err.error.message, err?.error?.title, {
                  timeOut: err.error?.duration || 5000,
                });
                break;
            }

          }

          if (err?.error?.redirect) {
            this.router.navigate(err?.error?.redirect);
          }
        }

        if (err.status === 500) {
          if (err.error.message) {
            this.toastr.error("Something went wrong, please contact the administrator", '', {
              timeOut: 3000,
            });
          }
        }

        return throwError(err);
      })
    );
  }
}
